<template>
  <div>
    <h2 class="mb-5">Titrage Acide-Base : Données et Calculs</h2>

    <h3 class="mb-4">Données</h3>

    <h4 class="mb-3">Partie A</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table1Caption"
        prefix="Tableau 1. "
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table style="max-width: 606px">
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 35%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 35%">Valeur</td>
            <td style="font-weight: bold; text-align: center; width: 30%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Volume de NaOH ajouté</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>[NaOH] originale</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Volume d'eau ajouté</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Partie B</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table2Caption"
        prefix="Tableau 2. "
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table style="max-width: 606px">
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 35%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 35%">Valeur</td>
            <td style="font-weight: bold; text-align: center; width: 30%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Volume initial d'eau dans le cylindre gradué de 10 mL</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitB1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Volume final d'eau dans le cylindre gradué de 10 mL</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitB2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Nombre de gouttes</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">gouttes</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Partie C</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table3Caption"
        prefix="Tableau 3. "
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{i}$" />
              dans la burette d'HCl
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{f}$" />
              dans la burette d'HCl
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              ajouté jusqu'au point final
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Partie D</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table4Caption"
        prefix="Tableau 4. "
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Numéro de l'acide inconnu</td>
            <td colspan="3" class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.unknownAcidNumber"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">---</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{i}$" />
              dans la burette de l'acide inconnu
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{f}$" />
              dans la burette de l'acide inconnu
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              ajouté jusqu'au point final
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h3 class="mb-4">Calculations</h3>

    <p class="mb-3">
      Calculez la concentration approximative de la solution de NaOH diluée que vous avez préparée
      en utilisant sa concentration initiale, le volume initial et le volume (total) final de la
      solution et l'équation
      <stemble-latex content="$\text{c}_1\text{V}_1=\text{c}_2\text{V}_2.$" />
      Rappelez- vous : la quantité totale (mol) est la même ! Il s'agit uniquement d'une valeur
      approximative, car
      <stemble-latex content="$\text{c}_1$" />
      est uniquement donné avec 1 chiffre significatif !
    </p>

    <calculation-input
      v-model="inputs.approxConcNaOH"
      class="mb-5"
      prepend-text="$\text{[NaOH]:}$"
      append-text="$\text{mol/L}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      Quelle était le volume moyen d'une goutte que vous avez déterminé lors de la calibration de
      votre compte-gouttes ?
    </p>

    <calculation-input
      v-model="inputs.averageDropVolume"
      class="mb-5"
      prepend-text="$\text{V}_\text{goutte}:$"
      append-text="$\text{mL/goutte}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      Veuillez sélectionner et télécharger une courbe de titrage pour l'un des titrages d'HCl
      ci-dessous. De plus, veuillez fournir un titre de figure et une légende dans le champ de texte
      situé sous le champ de téléchargement.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="standardizationTitrationCurve" multiple accept="application/pdf" />
    </p>

    <p class="mb-3">
      <v-text-field v-model="inputs.titrationCurve1Caption" label="Entrez le titre ici" />
    </p>

    <p class="mb-3">
      Remplissez la légende du tableau ci-dessous avec les données et les calculs suivants pour la
      titration de HCl avec NaOH.
    </p>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table5Caption"
        prefix="Tableau 5."
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              ajouté pour atteindre le point de virage
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mL</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$[\text{NaOH}]_\text{dil}$" />
              basé sur le point de virage
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$[\text{NaOH}]_\text{dil}$" />
              basé sur le point final
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>

          <tr>
            <td><stemble-latex content="$[\text{NaOH}]_\text{dil}$" /> moyenne</td>

            <td class="py-2 px-2 mx-0 centered-input" colspan="3">
              <calculation-input
                v-model="inputs.concNaOHAvg"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      Veuillez sélectionner et télécharger une courbe de titrage pour l'un des titrages de l'acide
      inconnu ci-dessous. De plus, veuillez fournir un titre de figure et une légende dans le champ
      de texte situé sous le champ de téléchargement.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="unknownTitrationCurve" multiple accept="application/pdf" />
    </p>

    <p class="mb-3">
      <v-text-field v-model="inputs.titrationCurve2Caption" label="Entrez le titre ici" />
    </p>

    <p class="mb-3">
      Remplissez la légende du tableau ci-dessous avec les données (et les valeurs calculées
      ultérieures) pour la titration de l'acide inconnu.
    </p>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table6Caption"
        prefix="Tableau 6."
        label=" Entrez ici une légende pour le tableau"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Propriété</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Essai 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unité</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              ajouté pour atteindre le point de virage
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mL</td>
          </tr>
          <tr>
            <td>[Acide Inconnu] basé sur le point de virage</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select
                v-model="inputs.concUnknownEqUnit"
                :items="unitOptions"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>[Acide Inconnu] basé sur le point final</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select
                v-model="inputs.concUnknownEPUnit"
                :items="unitOptions"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Volume au demi-point de virage</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select v-model="inputs.halfVeqUnit" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{pK}_\text{a}$" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">---</td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{pK}_\text{a}$" /> moyen</td>

            <td class="py-2 px-2 mx-0 centered-input" colspan="3">
              <calculation-input
                v-model="inputs.pKaAvg"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">---</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-2 mt-8">
      En fonction des données et des calculs effectués ici, quelle est l'identité de votre acide
      inconnu ?
    </p>

    <v-select v-model="inputs.acidIdentity" :items="acidOptions" outlined hide-details class="mb-4">
      <template #item="{item}">
        <stemble-latex class="no-text-transform" :content="item" />
      </template>
      <template #selection="{item}">
        <stemble-latex class="no-text-transform" :content="item" />
      </template>
    </v-select>

    <p class="mb-3 mt-4">
      Quelle est l'erreur en % par rapport à la valeur du
      <stemble-latex content="$\text{pK}_\text{a}$" />
      que vous avez déterminée ?
    </p>

    <calculation-input
      v-model="inputs.pctError"
      prepend-text="$\text{Erreur en pourcentage:}$"
      append-text="$\%$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'OttawaLab4ReportSheetFr',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        table1Caption: null,
        valueA1: null,
        unitA1: null,
        valueA2: null,
        unitA2: null,
        valueA3: null,
        unitA3: null,

        table2Caption: null,
        valueB1: null,
        unitB1: null,
        valueB2: null,
        unitB2: null,
        valueB3: null,
        unitB3: null,

        table3Caption: null,
        valueC1T1: null,
        valueC1T2: null,
        valueC1T3: null,
        unitC1: null,
        valueC2T1: null,
        valueC2T2: null,
        valueC2T3: null,
        unitC2: null,
        valueC3T1: null,
        valueC3T2: null,
        valueC3T3: null,
        unitC3: null,

        table4Caption: null,
        unknownAcidNumber: null,
        valueD1T1: null,
        valueD1T2: null,
        valueD1T3: null,
        unitD1: null,
        valueD2T1: null,
        valueD2T2: null,
        valueD2T3: null,
        unitD2: null,
        valueD3T1: null,
        valueD3T2: null,
        valueD3T3: null,
        unitD3: null,

        approxConcNaOH: null,
        averageDropVolume: null,

        titrationCurve1Caption: null,
        titrationCurve2Caption: null,

        VeqT1: null,
        concNaOHEqPointT1: null,
        concNaOHEndPointT1: null,

        VeqT2: null,
        concNaOHEqPointT2: null,
        concNaOHEndPointT2: null,

        VeqT3: null,
        concNaOHEqPointT3: null,
        concNaOHEndPointT3: null,

        VeqUnknownT1: null,
        concUnknownEqT1: null,
        concUnknownEPT1: null,
        halfVeqT1: null,
        pKaT1: null,

        VeqUnknownT2: null,
        concUnknownEqT2: null,
        concUnknownEPT2: null,
        halfVeqT2: null,
        pKaT2: null,

        VeqUnknownT3: null,
        concUnknownEqT3: null,
        concUnknownEPT3: null,
        halfVeqT3: null,
        pKaT3: null,

        concUnknownEqUnit: null,
        concUnknownEPUnit: null,
        halfVeqUnit: null,

        pctError: null,
        acidIdentity: null,

        concNaOHAvg: null,
        pKaAvg: null,

        table5Caption: null,
        table6Caption: null,
      },
      unitOptions: [
        '°C',
        'g',
        'g/L',
        'mL/goutte',
        'kg',
        'mL',
        'mol/L',
        'drop/mL',
        'g/mL',
        'K',
        'mol/kg',
        'gouttes/s',
        'min',
      ],
      acidOptions: [
        'Acide acétique',
        'Phthalate acide de potassium (KHP)',
        'Acide lactique',
        "Chlorure d'ammonium",
      ],
      standardizationTitrationCurve: [],
      unknownTitrationCurve: [],
    };
  },
  computed: {
    attachments() {
      return [...this.standardizationTitrationCurve, ...this.unknownTitrationCurve];
    },
  },
};
</script>

<style></style>
